<template>

    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
    </metainfo>

    <div>
        <!-- <div class="introAnimation">
            <div class="introAnimationContainer">
                NTCU
                <div class="stack" style="--stacks: 3;">
                    <span style="--index: 0;">113級數位系畢展</span>
                    <span style="--index: 1;">113級數位系畢展</span>
                    <span style="--index: 2;">113級數位系畢展</span>
                </div>
                <span class="right">Digital Content & Tech</span>
            </div>
        </div> -->

        
        <div class="introContainer">
            <section class="intro">

                <img src="@/assets/img/shatter/B.png" class="shatters" :style="{'--d': Math.random()*2}">
                <img src="@/assets/img/shatter/Y.png" class="shatters" :style="{'--d': Math.random()*2}">
                <img src="@/assets/img/shatter/P.png" class="shatters" :style="{'--d': Math.random()*2}">

                <div class="introBackground"></div>

                <div class="logoContainer">

                    <span class="img">
                        <img style="width: 100%" src="@/assets/img/logo_2.png">
                    </span>

                </div>

                <img class="introCharacter" src="@/assets/img/introCharacter.png">

            </section>
        </div>

        <shatterC :num="[0, 4, 6, 7]" :s="[1, 1, 1, 1]" :top="[-5, 5, -2, -10]" :left="[5, 60, 30, 90]" />

        <section class="news mt-5">

            <sectionTitle title="最新消息" style="margin-left: 120px;" />

            <div>
                <buttonScroller maxPressTimes="3" style="height: 40rem;">
                    <cardT v-for="(data, index) in newsData" :key="index" :title="data.title" :date="data.date" :href="data.href" :imgSrc="data.imgSrc" :infoType="data.infoType" :newNews="data.newNews">
                        <div v-html="data.info">
                        </div>
                    </cardT>
                </buttonScroller>
            </div>

            <!-- <div class="displayer">

                <buttonScroller maxPressTimes="3" style="height: 50vh;">
                    <cardT v-for="(data, index) in newsData" :key="index" :title="data.title" :date="data.date" :href="data.href" :imgSrc="data.imgSrc" :infoType="data.infoType" :newNews="data.newNews">
                        <div v-html="data.info">
                        </div>
                    </cardT>
                </buttonScroller>

            </div> -->
        </section>


        <introCharacterSection />

        <introVideoDisplay />

        <!-- <splitLine triangle=true /> -->
        
        <!-- <horizenScroll /> -->
<!-- 
        <section class="test" style="margin: 5rem 0;">

            <shatterC :num="[0, 4, 6, 7]" :s="[1, 1, 1, 1]" :top="[-5, 5, -2, -10]" :left="[5, 60, 30, 90]" />

            
        </section> -->

        
        <splitLine/>

        <!-- <section class="introVideoSection" style="position: relative;">
            <div class="dark"></div>

            <div class="introVideo" style="--r: 0deg">
                <h2>MOVIE</h2>
                <video controls>
                    <source src="@/assets/img/人工智慧_4.mp4" type="video/mp4">
                </video>
            </div>

        </section> -->

    </div>
</template>

<script setup>
// @ is an alias to /src
import splitLine from '@/components/splitLine/splitLine.vue'
import sectionTitle from '@/components/sectionTitle/sectionTitle.vue'
import cardT from '@/components/card/cardT.vue'
// import horizenScroll from '@/components/horizenScroll/horizenScroll.vue'
import buttonScroller from '@/components/buttonScroller/buttonScroller.vue'
import introCharacterSection from '@/components/introCharacterSection/introCharacterSection.vue'
import introVideoDisplay from '@/components/introVideoDisplay/introVideoDisplay.vue'
import shatterC from '@/components/shatter/shatterC.vue';
// import footerBar from '@/components/footerBar/footerBar.vue'
import { onMounted, ref, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

// const introVideo_Y = ref(0);
// const scrollPos = ref(0);
const introCharacter_Y = ref(1100);
// const moveLenght = ref(5);

const store = useStore();
// let introVideo_YInterval;

onMounted(()=>{
    window.addEventListener("scroll", onScroll)
    // introVideo_YInterval = setInterval(() => {
    //     introVideo_Y.value = document.querySelector('.introVideo').getBoundingClientRect().top + window.scrollY;
    // }, 2000);
    store.dispatch("setSidebarIndex", 0);

    setTimeout(() => {
        document.querySelector(".introAnimation").classList.add("active")
    }, 500);

})

onBeforeUnmount(()=>{
    window.removeEventListener("scroll", onScroll)
    // clearInterval(introVideo_YInterval);
})

const onScroll = ()=>{
    let y = window.scrollY;
    if(y < introCharacter_Y.value){
        //document.querySelector('.intro .bar').style.top = 'calc(40vh + ' + y*1.05 + 'px)';
    }
    
    // if(y >= introVideo_Y.value + 90*moveLenght.value){
    //     //document.querySelector('.introVideo').style.setProperty('--r', 90 - ((y - introVideo_Y) / moveLenght) + 'deg')
    //     document.querySelector('.introVideo h2').classList.remove("active");
    //     document.querySelector(".introVideoSection .dark").classList.remove("active");
    // }else if(y < introVideo_Y.value){
    //     //document.querySelector('.introVideo').style.setProperty('--r', 90 + 'deg')
    //     document.querySelector('.introVideo h2').classList.remove("active");
    //     document.querySelector(".introVideoSection .dark").classList.remove("active");

    // }else if(y > introVideo_Y.value){
    //     document.querySelector('.introVideo h2').classList.add("active");
    //     document.querySelector(".introVideoSection .dark").classList.add("active");

    // }

    document.querySelectorAll(".floating").forEach(function(item){
        item.style.transform = 'translate(0, calc(' + (y-(item.getBoundingClientRect().top+window.scrollY))*-0.1 + 'px * var(--s)))'
    })
}

const newsData = ref([]);

async function fetchNewsData(){

//獲取API
let api = await fetch("data/newsApi.json", {cache:"no-cache"}).then((res)=>{
    return res.text()
}).then((res)=>{
    return JSON.parse(res).api;
})
    
//呼叫NEWS資料
fetch(api, {cache: "no-cache"}).then((res)=>{
    return res.text();
}).then((response)=>{
    let data = response.split("\n");
    for(let i = 1 ; i < data.length /*& i < 5*/ ; i++){
        let row = data[i].replace("\r", "").split("\t");

        let infoType = 0;
        switch(row[5]){
            case '其他':
                infoType = 0
                break;
            case 'NEWS':
                infoType = 1
                break;
            case '影片':
                infoType = 2
                break;
            case '活動':
                infoType = 3
                break;
        }

        let newNews = false;
        if(row[6] == "TRUE")
            newNews = true;

        newsData.value.push({
            "title": row[0],
            "date": row[1],
            "info": row[2],
            "imgSrc": row[3],
            "href": row[4],
            "infoType": infoType,
            "newNews": newNews
        })
    }
})
}
fetchNewsData();

</script>

<style src="@/assets/css/Home.css">

</style>