<template>
	<div :class="'card ' + (href?'more':'')" @click="href?openHref():''">
		<!-- <div class="newBackgroundFilter"></div> -->
		<span class="new" v-show="newNews">NEW!</span>
		<div style="z-index: 1; padding: 3.5rem 2.5rem; margin: 2rem 0px;">
			<span class="image">
				<img :src="imgSrc!=''?imgSrc:'img/default.png'">
			</span>
			<div class="info">
				<div class="cardTitle">{{ title }}</div>

				<div style="display: grid; grid-template-columns: 1fr 2fr; gap: 4px; height: 20px; line-height:20px">
					<span :class="'infoType ' + infoTypeStringColor[infoType]">{{ infoTypeString[infoType] }}</span>

					<span class="fa fa-clock-o fs-6" style="height: 30px; line-height:30px">
						{{ date }}
					</span>

				</div>

				<div style="display: block; height: 5rem; overflow-y: hidden;">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>

// export default{
// 	name: 'cardT',
// 	props:{
// 		title:{
// 			type: String
// 		},
// 		href:{
// 			type: String
// 		},
// 		date:{
// 			type: String
// 		},
// 		img:{
// 			type: String
// 		},
// 		infoType:{
// 			type: Number
// 		},
// 		info:{
// 			type: String
// 		}
// 	}
// }

import { defineProps, ref } from 'vue';

const props = defineProps({
	title:{
		type: String
	},
	href:{
		type: String
	},
	date:{
		type: String
	},
	imgSrc:{
		type: String,
		default: "img/default.png"
	},
	infoType:{
		type: Number,
		default: 0
	},
	newNews:{
		type: Boolean,
		default: false
	}
});

function openHref(){
	window.open(props.href)
}

const infoTypeString = ref(['其他', 'NEWS', '影片', '活動'])
const infoTypeStringColor = ref(['blue', 'green', 'red', 'yellow'])

</script>

<style>

.card {
	position: relative;
	/* flex: 1; */
	background: url("@/assets/img/newsCardBackground.png");
	background-position: center;
	background-size: 100% 100%;
	transition: 0.25s;
	padding: 1rem;
	margin: 1rem;
	aspect-ratio: 9/16;
	font-family: var(--bs-font-monospace);
	color: white;
	display: block;
	min-width: 20rem;
}
/* 
.card .newBackgroundFilter{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-image: url("@/assets/img/background_texture.png");
    mix-blend-mode: multiply;
    opacity: 0.4;
	margin: 0;
	z-index: 0;
} */

/* .card:hover {
	background-color: var(--backgroundColorLightTransHover);
	transform: scale(1.05);
} */

.card.more {
	cursor: pointer;
}

.card.more::after {
	content: "》";
	position: absolute;
	bottom: 7rem;
	text-align: center;
	user-select: none;
	writing-mode: vertical-lr;
	left: 50%;
	transform: translate(-50%, 0);
	color: white;
	font-size: 2rem;
	transition: 0.2s;
	font-weight: 900;
	display: block;
	z-index: 1;
}

.card.more:hover::after {
	transform: translate(-50%, 20%);
	/* color: lightgray; */
}

.card .info {
	padding: 0 1rem;
}

.card .new{
	position: absolute;
	left: 1rem;
	top: 2rem;
	/* background-color: #FF0000; */
	/* border-radius: 4rem; */
	/* transform: skew(-20deg); */
	font-size: 1.5rem;
	padding: 0.2rem 1rem;
	font-weight: 800;
	color: white;
	z-index: 2;
}

.card .new::before{
	content: "NEW!";
	color: #FF0000 !important;
	position: absolute;
	left: 0;
	top: 0;
	background-color: #FF0000;
	/* border-radius: 4rem; */
	transform: skew(-30deg);
	font-size: 1.5rem;
	padding: 0.2rem 1rem;
	font-weight: 800;
	color: white;
	z-index: -1;
}

.card .cardTitle {
	font-size: 1.4rem;
	text-align: left;
	font-weight: 900;
	text-align: center;
}

.card .image {
	display: block;
	aspect-ratio: 16/9;
	/*background: var(--img);*/
	/* background-size: cover;
	background-position: center; */
	overflow: hidden;
	/* border-radius: 2rem; */
}

.card .image img{
	width: 100%;
	aspect-ratio: 16/9;
	transition: 0.2s;
}

.card:hover .image img{
	transform: scale(1.1);
}

.card .image img{
	width: 100%;
	aspect-ratio: 16/9;
}

.card div {
	margin: 0.5rem 0;
}

.card .infoType{
	font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    display: block;
    text-align: center;
    width: 50px;
    height: 30px;

	color: #fff;
	border: 1px solid #fff;
	box-shadow: #fff 0 0 8px;

    /* background-size: 60px 30px; */

	/* background-image: url("@/assets/img/brush/brush.png"); */
	/* background-position: center calc(100% + 1px); */
	/* background-repeat: no-repeat; */
}

.card .infoType.gray{
	color: #fff;
	border: 1px solid #fff;
	box-shadow: #fff 0 0 8px;
}
.card .infoType.blue{
	color: #6cbaff;
	border: 1px solid #6cbaff;
	box-shadow: #6cbaff 0 0 8px;
}
.card .infoType.red{
	color: rgb(255, 0, 179);
	border: 1px solid rgb(255, 0, 179);
	box-shadow: rgb(255, 0, 179) 0 0 8px;
}
.card .infoType.green{
	color: rgb(0, 255, 89);
	border: 1px solid rgb(0, 255, 89);
	box-shadow: rgb(0, 255, 89) 0 0 8px;

}
.card .infoType.yellow{
	color: rgb(255, 208, 0);
	border: 1px solid rgb(255, 208, 0);
	box-shadow: rgb(255, 208, 0) 0 0 8px;
}
</style>
