<template>

    <div class="logo">
        <router-link to="/"><img style="width: 100%;" src="@/assets/img/logo_text.png"></router-link>
        <!-- <div style="font-weight: 900; font-size: 2rem;"><i>113級畢業成果展</i></div>
        <div style="font-weight: 900;"><i>國立臺中教育大學數位內容科技學系</i></div> -->
        <!-- <div class="cuurentPageTitle" style="text-align: center; width: 100%">
            {{ routerName[store.state.sidebarIndex].name }}
        </div> -->
    </div>

    <sideBar :nowIndex="sidebarIndex" @indexChange="changeNowIndex" />
    <!-- <backgroundRipple/> -->

    <router-view/>
    <footerBar/>

</template>

<script setup>
// @ is an alias to /src
import sideBar from '@/components/sideBar/sideBar.vue'
// import backgroundRipple from '@/components/backgroundRipple/backgroundRipple.vue'
import footerBar from '@/components/footerBar/footerBar.vue'

import { onMounted, onUnmounted } from "vue";
// import { useStore } from 'vuex';

// const store = useStore();
// const routerName = ref(store.state.route);

var scale = document.body.clientWidth/300;
function reScale(){
    scale = document.body.clientWidth/300;
    document.body.style.setProperty("--scale", scale);
}

onMounted(()=>{
    
    document.body.style.setProperty("--scale", scale);
    window.addEventListener("resize", reScale)

    window.addEventListener("load", ()=>{
        document.querySelector("#loading").classList.add("loaded")
    })
})

onUnmounted(()=>{
    window.removeEventListener("resize", reScale);
})

</script>

<style src="@/assets/css/app.css">

</style>
