<template>

    <div class="splitLine">
        <span :class=" triangle ? 'line triangle' : 'line'" style="--l:65%; --tl:20%"></span>
        <span class="line" style="--l:80%"></span>
        <span class="line" style="--l:60%"></span>
    </div>

</template>

<script>
export default {
    name: 'splitLine',
    data(){

    },
    props:{
        triangle:{
            type: Boolean,
            required: false
        }
    }
}
</script>


<style>
.splitLine{
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
}

.splitLine .line{
    margin: 0.5rem 0;
    position: relative;
    display: block;
    width: var(--l);
    border-bottom: 2px #fff solid;
}

.splitLine .line.dot::before{
    content: "";
    display: block;
    position: absolute;
    left: -0.5rem;
    top: -0.25rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: transparent;
    border: 1px #fff solid;
}

.splitLine .line.dot::after{
    content: "";
    display: block;
    position: absolute;
    right: -0.5rem;
    top: -0.25rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: transparent;
    border: 1px var(--WordColor_Black) solid;
}

.splitLine .line.triangle::after{
    content: "";
    display: block;
    position: absolute;
    left: 100%;
    bottom: 0;
    width: calc(var(--tl));
    background: transparent;
    border-radius: 0;
    height: 0;
    border: none;
    border-bottom: 1px var(--WordColor_Black) solid;
    border-top: 1px var(--WordColor_Black) solid;
    transform: rotate(-45deg);
    transform-origin: left;
}
</style>