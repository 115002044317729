<template>

    <div class="shatterContainer" ref="shatterContainer">
        
        <!-- <img class="floating shatter" src="@/assets/img/shatter/shatter1.png" style="--s:1; top: -5rem; left: 10%; filter: invert(50%) opacity(0.9);"> -->
        <!-- <img class="floating shatter" src="@/assets/img/shatter/shatter3.png" style="--s:1.1; top: 5em; right: 40%; filter: invert(50%) opacity(0.9);"> -->
        <!-- <img class="floating shatter" src="@/assets/img/shatter/shatter5.png" style="--s:0.8; top: -2rem;left: 30%; filter: invert(50%) opacity(0.9);"> -->
        <!-- <img class="floating shatter" src="@/assets/img/shatter/shatter4.png" style="--s:0.9; top: -10rem; right: 5%; filter: invert(50%) opacity(0.9);"> -->
        <img class="floating shatter" src="@/assets/img/shatter/B1.png" style="--s:0.9; top: -10rem; right: 5%;">
        <img class="floating shatter" src="@/assets/img/shatter/B2.png" style="--s:0.9; top: -10rem; right: 5%;">
        <img class="floating shatter" src="@/assets/img/shatter/P1.png" style="--s:0.9; top: -10rem; right: 5%;">
        <img class="floating shatter" src="@/assets/img/shatter/P2.png" style="--s:0.9; top: -10rem; right: 5%;">
        <img class="floating shatter" src="@/assets/img/shatter/P3.png" style="--s:0.9; top: -10rem; right: 5%;">
        <img class="floating shatter" src="@/assets/img/shatter/Y1.png" style="--s:0.9; top: -10rem; right: 5%;">
        <img class="floating shatter" src="@/assets/img/shatter/Y2.png" style="--s:0.9; top: -10rem; right: 5%;">
        <img class="floating shatter" src="@/assets/img/shatter/Y3.png" style="--s:0.9; top: -10rem; right: 5%;">

    </div>

</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue';

const shatterContainer = ref();
const props = defineProps({
    num:{
        type: Array,
        required: true
    },
    s:{
        type: Array,
        required: true
    },
    top:{
        type: Array,
        required: true
    },
    left:{
        type: Array,
        required: true
    }
});

onMounted(()=>{

    shatterContainer.value.querySelectorAll(".shatter").forEach((item)=>{
        item.hidden = true;
    })
    console.log(props.s);

    for(let i = 0 ; i < props.num.length ; i++){
        let s = props.s[i]
        let top = props.top[i] + "rem";
        let left = props.left[i] + "%";
        console.log(s)
        let item = shatterContainer.value.querySelectorAll(".shatter")[props.num[i]]
        item.style = "--s:" + s + "; top:" + top + "; left:" + left + ";";
        item.hidden = false;
    }

    // for(let i = 0 ; i < props.num ; i++){
    //     let s = 1+((Math.random() * 5)-2)/10;
    //     let top = (Math.random() * 20 - 10) + "rem";
    //     let left = (Math.random() * 110 - 10) + "%";
    //     console.log(s)
    //     let item = shatterContainer.value.querySelectorAll(".shatter")[Math.floor(Math.random() * 8)]
    //     item.style = "--s:" + s + "; top:" + top + "; left:" + left + ";";
    //     item.hidden = false;
    // }

})




</script>

<style>

/*向上漂浮物件*/
.floating{
    position: absolute;
    transition: 0.2s;
}

/*碎片*/
.shatterContainer{
    position: absolute;
    width: 100vw;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    z-index: -1;
}

.shatter{
    scale: var(--s);
}

</style>