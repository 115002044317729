<template>
 
    <span class="sidebarButton" onclick="document.querySelector('.sidebar').classList.toggle('active');">
        <span>
            ≡
        </span>
    </span>

    <span class="scrollToTop" onclick="window.scrollTo({top:0, behavior:'smooth'})">↑</span>

    <div class="sidebar" id="sidebar" onclick="document.querySelector('.sidebar').classList.remove('active');">
        <!-- <div class="background">
            <span class="ver" style="--i:0"></span>
            <span style="--i:1"></span>
            <span style="--i:2"></span>
            <span style="--i:3"></span>
            <span style="--i:4"></span>
        </div>

        <div class="noise"></div> -->

        <ul class="menu">
            <li v-for="(item, index) in route" :key="index" :class="indexNow==index?'active':''" :style="{'--i':index}" @click="changeIndex(index)" onclick="document.querySelector('.sidebar').classList.remove('active');">
                <router-link :to="item.link">
                    <span>{{ item.name }}</span>
                </router-link>
            </li>
        </ul>
    </div>

</template>

<script setup>
import { computed } from '@vue/reactivity';
import { reactive } from 'vue';
import { useStore } from 'vuex';




const store = useStore();

const route = reactive(store.state.route);

const changeIndex = (val)=>{
    store.dispatch("setSidebarIndex", val);

    //順便移動到最上方
    window.scrollTo({top: 0});
}

const indexNow = computed(()=>{
    return store.getters.indexNow;
})

</script>

<style>

.sidebarButton{
    position: fixed;
    top: 5%;
    right: -1rem;
    z-index: 100;
    
    user-select: none;
    cursor: pointer;
    filter: drop-shadow(-2px 0px 0px rgb(8, 170, 255)) drop-shadow(0px 2px 0px rgb(8, 170, 255)) drop-shadow(0px -2px 0px rgb(8, 170, 255)) drop-shadow(-4px 0 20px #0e11c8);

}

.sidebarButton span{
    background-color: #525252;
    backdrop-filter: blur(4px);
    color: white;
    font-size: 2rem;
    clip-path: polygon(0 50%, 25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%);
    padding: 0.5rem 1rem;
}

.sidebar{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 99;
    visibility: hidden;
    overflow: hidden;
    transition: 0.2s;
}

.sidebar.active{
    visibility: visible;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);

}

.sidebar .menu{
    position: absolute;
    opacity: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    visibility: hidden;
    text-align: center;
}

.sidebar.active .menu{
    /*left: calc(25% + (75% / 2));*/
    opacity: 1;
    visibility: visible;
}

.sidebar.active .menu{
    list-style: none;
}

.sidebar .menu a{
    color: white;
    text-decoration: none;
    font-size: 2rem;
}

.sidebar .menu li{
    position: relative;
    opacity: 0;
    transition: 0.1s;
    transition-delay: calc(var(--i) * 0.05s);
}

.sidebar.active .menu li{
    opacity: 1;
}

.sidebar .menu li::before{
    content: '》';
    display: block;
    background-color: transparent;
    position: absolute;
    left: -2.5rem;
    top: 0;
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}

.sidebar .menu li a::after{
    content: "";
    display: block;
    background-color: #fff;
    position: absolute;
    left: -1rem;
    top: 0;
    width: 4px;
    height: 0;
    transition: 0.2s;
    transition-delay: calc(var(--i) * 0.03s);
}

.sidebar.active .menu li a::after{
    height: 90%;
}

.sidebar .menu li:hover::before, .sidebar .menu li.active::before{
    opacity: 1;
    visibility: visible;
}

.scrollToTop{
    position: fixed;
    display: block;
    bottom: 10%;
    right: 0.1rem;
    width: 3rem;
    height: 3rem;
    background: rgba(60, 60, 60, 0.8);
    color: var(--WordColor);
    cursor: pointer;
    text-align: center;
    line-height: 3rem;
    z-index: 99;
}
</style>
