<template>

<div class="verticalText" ref="verticalText">
    <div class="title">
        <slot name="title"></slot>
    </div>
    <div class="text">
        <slot name="text"></slot>
    </div>
</div>


</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue';

const verticalText = ref();
const props = defineProps({
    noAni:{
        type: Boolean,
        default: false
    }
})


onMounted(()=>{
    if(props.noAni)
        verticalText.value.classList.add("active")
    else
        observer.observe(verticalText.value);
})
//到達時啟動
const observer = new IntersectionObserver((items)=>{
    items.forEach((item)=>{
        if(item.isIntersecting){
            item.target.classList.add("active");

        }else{
            item.target.classList.remove("active");
        }
    })
})
</script>

<style>

.verticalText{
    position: absolute;
    right: 0;
    opacity: 0;
    top: 1rem;
    writing-mode: vertical-rl;
    /* color: rgba(255, 255, 255, 0.2); */
    color: #606465;
    user-select: none;
    z-index: -1;
    transition: 0.2s;
    transition-delay: 0.2s;
    transform: translate(100%, 0);
}

.verticalText::before{
    content: "";
    position: absolute;
    background-color: #25a3d5;
    top: -1rem;
    right: 0.5rem;
    width: 0.5rem;
    height: 50%;
    display: block;
}

.verticalText .title{
    font-size: 4rem;
    font-weight: 900;
}

.verticalText .text{
    font-size: 1rem;
    font-weight: 900;
}

.verticalText.active{
    right: 0;
    opacity: 1;
    transform: translate(0, 0);

}

</style>