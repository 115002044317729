<template>

<div style="background: rgba(37, 54, 63, 1); height: 110vh; position: relative; overflow: hidden; z-index: 0;">

    <verticalText>
        <template #title>INTRO VIDEO</template>
        <template #text>
            VIDEO \\\*u*///
            <br>
            VISIT OUR EXHIBITION
        </template>
    </verticalText>

    <span id="logoWindow" class="dragableDisplayer noTransform" ref="logoWindow" style="max-width: 90vw; max-height: 70vh; z-index: 3;">
        <div id="logoWindowHeader" class="dragableDisplayerHeader">● ● ●</div>
        <div class="dragableDisplayerContent">
            <img style="width: 100%;" src="@/assets/img/logo_2.png">
        </div>
        <div class="dragableDisplayerFooter">
            <span>
                LOGO
            </span>
            <span>
                <i class="fa fa-share-alt"></i>
                <span> </span>
                <i class="fa fa-thumbs-o-up"></i>
            </span>
        </div>
    </span>


    <span id="videoWindow" class="dragableDisplayer" ref="videoWindow" style="max-width: 90vw; max-height: 70vh; z-index: 5; left: 50%; top: 50%;">
        <div id="videoWindowHeader" class="dragableDisplayerHeader"><span style="font-weight: 900;" onclick="document.querySelector('#videoWindow video').requestPictureInPicture()">—</span> ● ●</div>
        <div class="dragableDisplayerContent">
            <video style="width: 100%;" src="@/assets/img/人工智慧_最終.mp4" controls></video>
        </div>
        <div class="dragableDisplayerFooter">
            <span>
                113級數位系畢展宣傳
            </span>
            <span>
                <i class="fa fa-share-alt"></i>
                <span> </span>
                <i class="fa fa-thumbs-o-up"></i>
            </span>
        </div>
    </span>

    <span id="fbWindow" class="dragableDisplayer noTransform" ref="fbWindow" style="max-width: 90vw; max-height: 70vh; z-index: 4; right: 0; bottom: 0;">
        <div id="fbWindowHeader" class="dragableDisplayerHeader">● ● ●</div>
        <div class="dragableDisplayerContent" style="padding: 2rem 0;">
            <div class="socialMediaButtonContainer">
                <a class="fa fa-facebook socialMediaButton" style="--c:#1b74e4" href="https://www.facebook.com/profile.php?id=100086739893931" target="_blank"></a>
                <a class="fa fa-instagram socialMediaButton" style="--c:radial-gradient(
                        circle farthest-corner at 28% 100%,
                        #fcdf8f 0%,
                        #fbd377 10%,
                        #fa8e37 22%,
                        #f73344 35%,
                        transparent 65%
                    ), linear-gradient(145deg, #3051f1 10%, #c92bb7 70%);" href="https://www.instagram.com/ntcudct113_he11o.liv3r/" target="_blank"></a>
            </div>
        </div>
        <div class="dragableDisplayerFooter">
            <span>
                FB粉專＆IG
            </span>
            <span>
                <i class="fa fa-share-alt"></i>
                <span> </span>
                <i class="fa fa-thumbs-o-up"></i>
            </span>
        </div>
    </span>

    <span id="iframeWindow" class="dragableDisplayer noTransform" ref="iframeWindow" style="max-width: 90vw; max-height: 70vh; z-index: 3; right: 2rem; top: 2rem; width: 30vw;">
        <div id="iframeWindowHeader" class="dragableDisplayerHeader">● ● ●</div>
        <div class="dragableDisplayerContent" style="height: 25rem;">
            <iframe :src="iframeData.src" style="border:none; width: 100%; height: 100%;" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </div>
        <div class="dragableDisplayerFooter">
            <span>
                {{ iframeData.title }}
            </span>
            <span>
                <i class="fa fa-share-alt"></i>
                <span> </span>
                <i class="fa fa-thumbs-o-up"></i>
            </span>
        </div>
    </span>

</div>

</template>

<script setup>
import { onMounted, ref } from 'vue';
import verticalText from '../verticalText/verticalText.vue';

const logoWindow = ref();
const videoWindow = ref();
const fbWindow = ref();
const iframeWindow = ref();
const iframeData = ref({
    title: "璃兒中之人招募中",
    src: "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid031yAfdHpVXwqnuzbiUUxrvt8wWvEL25eCXXn595DiQRuaXo9DGETQfohjHZTsU7cfl%26id%3D100086739893931&show_text=true&width=500"
});

onMounted(()=>{
    dragElement(videoWindow.value);
    dragElement(logoWindow.value);
    dragElement(fbWindow.value);
    dragElement(iframeWindow.value);

    // videoWindow.value.style.left = screen.width/2 + "px";
    // console.log(screen.width/2 - logoWindow.value.offsetWidth/2 + "px");
    // videoWindow.value.style.top = screen.height/2 -20 + "px";
    // console.log(screen.height/2 - logoWindow.value.offsetHeight/2 + "px");

    // fbWindow.value.style.left = screen.width - fbWindow.value.offsetWidth*2 + "px";
    // fbWindow.value.style.top = screen.height - fbWindow.value.offsetHeight*2 + "px";

    observer.observe(videoWindow.value);
    observer.observe(logoWindow.value);
    observer.observe(fbWindow.value);
    observer.observe(iframeWindow.value);

    document.querySelectorAll(".dragableDisplayer").forEach((item)=>{
        item.onmousedown = ()=>{
            document.querySelectorAll(".dragableDisplayer").forEach((other)=>{
                other.style.zIndex -= 1;
            })

            item.style.zIndex = 999;
        }
    })
})

//到達時啟動
const observer = new IntersectionObserver((items)=>{
    items.forEach((item)=>{
        if(item.isIntersecting){
            item.target.classList.add("active");

            if(item.target.querySelector("video"))
                item.target.querySelector("video").play();
        }
        // else{
        //     item.target.classList.remove("active");
        // }
    })
})

//拖動
function dragElement(elmnt) {
    //console.log(elmnt)
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (document.getElementById(elmnt.id + "Header")) {
    // if present, the header is where you move the DIV from:
    document.getElementById(elmnt.id + "Header").onmousedown = dragMouseDown;
  } else {
    // otherwise, move the DIV from anywhere inside the DIV:
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;

    document.ontouchmove = elementDrag;
    document.ontouchend = closeDragElement;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    
    
    // if((elmnt.offsetTop - pos2) < 0 || (elmnt.offsetTop - pos2) > window.innerHeight - elmnt.offsetHeight)
    //     return

    // if((elmnt.offsetLeft - pos1) < 0 || (elmnt.offsetLeft - pos1) > window.innerWidth - elmnt.offsetWidth)
    //     return
    

    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
    document.ontouchmove = null;
    document.ontouchend = null;
  }
}

//讀取資料
fetch("data/homeIframeData.json", {cache: "no-cache"}).then((res)=>{
    return res.text();
}).then((res)=>{
    iframeData.value = JSON.parse(res);
})

</script>

<style>

.dragableDisplayer {
    box-sizing: border-box;
    position: absolute;
    display: block;
    /* padding: 0.5rem; */
    border-radius: 1rem;
    border: 2px solid white;
    aspect-ratio: 16/9;
    filter: drop-shadow(0 0 2px white);
    transform: translate(-50%, -50%);
    /* transition-timing-function: cubic-bezier(.36,.03,.72,1.55); */
    scale: 0;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(4px);
    visibility: hidden;
}

.dragableDisplayer.noTransform{
    transform: none;
}

.dragableDisplayer.active{
    visibility: visible;
    animation: scaleUP 0.3s cubic-bezier(.36,.03,.72,1.55) forwards;
}

@keyframes scaleUP {
    0%{
        scale: 0;
    }    
    100%{
        scale: 1;
    }
}

.dragableDisplayerHeader{
    text-align: right;
    cursor: pointer;
    border-bottom: 2px solid white;
    color: white;
    font-size: 0.5rem;
    line-height: 2rem;
    letter-spacing: 6px;
    padding: 0.5rem;
}

.dragableDisplayerFooter{
    display: flex;
    justify-content: space-between;
    border-top: 2px solid white;
    color: white;
    font-size: 1.4rem;
    line-height: 1.5rem;
    letter-spacing: 6px;
    padding: 0.8rem;
}

#videoWindow{
    width: 60vw;
}

#logoWindow, #fbWindow{
    width: 30vw;
}
/* 
#fbWindow{
    width: 50vw;
} */

@media screen and (max-width: 768px) {

    #videoWindow{
        width: 90vw;
    }

    #fbWindow, #logoWindow{
        width: 50vw;
    }

    .dragableDisplayerFooter{
        font-size: 1.0rem;
    }
    
}

.socialMediaButtonContainer{
    position: relative;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.socialMediaButton{
    color: white;
    background: var(--c);
    border-radius: 0.5rem;
    display: block;
    height: 3rem;
    width: 3rem;
    line-height: 3rem;
    font-size: 2rem;
    text-decoration: none;
    text-align: center;
    margin: 1.5rem;
    transition: 0.2s;
}


.socialMediaButton:hover{
    color: white;
    opacity: 0.7;
}

</style>